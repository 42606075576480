.portfolio-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.mobile-header {
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(25, 28, 32, 0.08);
  display: flex;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
}

.logo {
  height: 24px;
  width: 78.76px;
  object-fit: cover;
}

.mobile-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 40px;
}

.mobile-title-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.mobile-badge {
  background-color: rgba(35, 75, 250, 0.04);
  border-radius: 80px;
  display: inline-flex;
  padding: 8px 16px;
}

.mobile-badge span {
  color: #234BFA;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.56px;
  line-height: 22.8px;
}

.mobile-title-section h1 {
  color: #191C20;
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -1.6px;
  line-height: 50px;
  opacity: 0.8;
}

.mobile-divider {
  background-color: rgba(25, 28, 32, 0.08);
  height: 1px;
  width: 100%;
}

.mobile-projects {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.featured-project {
  background-size: cover;
  border-radius: 4px;
  height: 480px;
  position: relative;
  overflow: hidden;
}

.featured-content {
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
  bottom: 0;
  padding: 24px;
  position: absolute;
  width: 100%;
}

.featured-label {
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.8px;
  padding: 10px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.mobile-project-card {
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  width: 100%;
}

.mobile-project-card img {
  height: 240px;
  width: 100%;
  object-fit: cover;
}

.mobile-project-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px 24px;
}

.mobile-project-info h2 {
  color: #191C20;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.mobile-project-info p {
  color: #51555A;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.mobile-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.mobile-tag {
  background-color: rgba(235, 235, 235, 0.64);
  border-radius: 80px;
  color: #191C20;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  padding: 4px 16px;
}