/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
} */

/* Global cursor styles */
html, 
body,
a, 
button, 
.btn, 
.nav-link, 
[role="button"],
input[type="button"],
input[type="submit"],
.service-card,
.checkBox label,
.swiper-button-next,
.swiper-button-prev,
[onclick],
.clickable {
  cursor: none !important;
}

body {
  height: 100vh;
}

.cursor-dot,
.cursor-dot-outline {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
  transition: transform 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: #234efa;
}

.cursor-dot-outline {
  width: 20px;
  height: 20px;
  border: 2px solid #234efa;
}