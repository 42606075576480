@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  box-sizing: border-box;
}
body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
p {
  margin-top: 0;
}
p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}

.text-center {
  text-align: center;
}

.td-container {
  max-width: 80%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.td-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.td-row > div {
  /* padding-left: 15px; */
  /* padding-right: 15px; */
}
.alignCenter {
  align-items: center;
}
.td-row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  /* padding-right: 15px; */
  padding-left: 15px;
}
.td-col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.3333333333%;
}
.td-col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.td-col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}
.td-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.td-col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.6666666667%;
}
.td-col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}
.td-col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.3333333333%;
}
.td-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.6666666667%;
}
.td-col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}
.td-col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.3333333333%;
}
.td-col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.6666666667%;
}
.td-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.btn,
a {
  text-decoration: none !important;
  transition: all ease 0.3s;
  display: inline-block;
}
.blueBtn {
  padding: 10px 20px;
  color: #fff;
  background-color: #234efa;
  border-radius: 10px;
}
.blueBtn {
  padding: 10px 20px;
  color: #fff;
  background-color: #234efa;
  border-radius: 10px;
}
.blueBtn:hover {
  background-color: #1a3bc7;
  color: #fff;
}

.secPadding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.siteHeader {
  padding-top: 15px;
  padding-bottom: 15px;
}
.siteHeader .td-row {
  justify-content: space-between;
  align-items: center;
}
.siteHeader ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.siteHeader ul li {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.siteHeader ul li a {
  text-decoration: none !important;
  color: #000;
}
.siteHeader ul li a:hover {
  color: #234efa;
}
.siteHeader .btnBox a {
  color: #000;
  text-decoration: none !important;
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 10px;
}
.siteHeader .btnBox a:hover {
  background-color: #234efa;
  color: #fff;
  border-color: #234efa;
}

.heroSec {
  padding-top: 100px;
  padding-bottom: 160px;
}
.heroSec h2 {
  font-size: 68px;
  font-weight: 700;
  font-family: 'Satoshi', sans-serif;
  margin-bottom: 30px;
}
.heroSec h2 span {
  display: inline-block;
  background: -webkit-linear-gradient(60deg, #234efa, #23c8fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.heroSec .bottomContent {
  font-size: 14px;
}
.heroSec .bottomContent .td-col {
  max-width: 250px;
  width: 50%;
}
.heroSec .bottomContent h3 {
  font-size: 32px;
  margin-bottom: 15px;
}
.heroSec .mainContent {
  margin-bottom: 70px;
}
.heroSec .mainContent p {
  max-width: 550px;
  width: 100%;
  margin-bottom: 30px;
}
.heroSec .profileBox {
  min-width: 350px;
  position: relative;
}
.heroSec .profileBox .mainProfile {
  padding: 50px 30px;
  position: relative;
  border: 4px solid rgba(236, 236, 236, 0.64);
  border-radius: 30px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.heroSec .profileBox:after,
.heroSec .profileBox:before {
  content: '';
  background-color: #fff;
  border-radius: 30px;
  border: 4px solid rgba(236, 236, 236, 0.64);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.heroSec .profileBox:before {
  z-index: 2;
  left: 30px;
  top: 30px;
}
.heroSec .profileBox:after {
  z-index: 1;
  left: 60px;
  top: 60px;
}
.heroSec .td-col-5 {
  justify-content: center;
  display: flex;
}
.heroSec .profileBox .tagBox {
  position: absolute;
  width: auto;
  top: -17px;
  background-color: #000;
  color: #fff;
  padding: 5px 15px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
}
.heroSec .profileBox .imgBox {
  margin-bottom: 20px;
  position: relative;
}
.heroSec .profileBox .icon {
  position: absolute;
  bottom: 0;
  right: 15%;
}
.heroSec .profileBox .imgBox img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
}
.heroSec .profileBox h3 {
  font-size: 32px;
  margin-bottom: 10px;
}
.heroSec .profileBox p {
  font-size: 21px;
}
.heroSec .profileBox .btn {
  position: absolute;
  top: 100%;
  margin-top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  color: #000;
}
.heroSec .profileBox .btn:hover {
  background-color: #000;
  color: #fff;
}
.heroSec .profileBox .btn:hover path {
  stroke: #fff;
}

.logoSection {
  text-align: center;
  background-color: #f7f7f7;
}
.logoSection h2 {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #191c20;
}
.logoSection .td-row {
  justify-content: center;
}

/*Core Feature section*/
.coreFeature {
  background-color: #fff;
}
.coreFeature .tag {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
  background: rgba(35, 75, 250, 0.04);
  border-radius: 80px;
  padding: 12px 22px;
  margin-bottom: 15px;
  display: inline-block;
}
.coreFeature .tag img {
  margin-right: 5px;
}
.coreFeature .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #191c20;
  margin-bottom: 15px;
}
.coreFeature .para {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
  margin-bottom: 40px;
}
.coreFeature .blackBtn {
  padding: 12px 16px;
  background: #191c20;
  border-radius: 8px;
  color: #fff !important;
}
.coreFeature .boxarea img {
}
.coreFeature .boxarea h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #191c20;
}
.coreFeature .boxarea p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
}
.coreFeature .boxarea a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
}

/*Our Process section*/
.ourProcess {
  background-color: #fff;
}
.ourProcess .tag {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
  background: rgba(35, 75, 250, 0.04);
  border-radius: 80px;
  padding: 12px 22px;
  margin-bottom: 15px;
  display: inline-block;
}
.ourProcess .tag img {
  margin-right: 5px;
}
.ourProcess .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #191c20;
  margin-bottom: 15px;
}
.ourProcess .para {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
  margin-bottom: 40px;
}
.ourProcess .blackBtn {
  padding: 12px 16px;
  background: #191c20;
  border-radius: 8px;
  color: #fff;
}
.ourProcess .boxarea {
  text-align: center;
  padding: 0 80px;
  position: relative;
}
.ourProcess .boxarea img {
}
.ourProcess .boxarea h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #191c20;
}
.ourProcess .boxarea p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
}
.ourProcess .boxarea a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
}
.ourProcess .boxarea.top-arrow:after {
  content: '';
  background: url(images/top-arrow.png);
  top: 45px;
  width: 100%;
  height: 40px;
  position: absolute;
  right: -130px;
  background-repeat: no-repeat;
  background-position: right;
}
.ourProcess .boxarea.bottom-arrow:after {
  content: '';
  background: url(images/bottom-arrow.png);
  top: 30px;
  width: 100%;
  height: 40px;
  position: absolute;
  right: -130px;
  background-repeat: no-repeat;
  background-position: right;
}

/*Slider section*/
.sliderSection {
  background: rgba(236, 236, 236, 0.4);
}
.sliderSection .content-area .img-part {
  text-align: right;
}
.sliderSection .content-area .img-part img {
  border-radius: 20px;
}
.sliderSection .content-area .text-part {
  text-align: left;
}
.sliderSection .content-area .text-part .heading {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #191c20;
  padding: 0 110px 0 60px;
}
.sliderSection .content-area .text-part .qoute {
  padding: 0 110px 0 60px;
  margin: 0 0 25px 0;
}
.sliderSection .content-area .text-part .name {
  padding: 0 110px 0 60px;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #191c20;
}
.sliderSection .content-area .text-part small {
  padding: 0 110px 0 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.03em;
  color: rgba(25, 28, 32, 0.64);
}
.sliderSection .content-area .text-part .client-logo {
  padding: 0 110px 0 60px;
  margin: 22px 0 0 0;
  display: block;
}

/*Blog section*/
.blog {
  background-color: #fff;
}
.blog .tag {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
  background: rgba(35, 75, 250, 0.04);
  border-radius: 80px;
  padding: 12px 22px;
  margin-bottom: 15px;
  display: inline-block;
}
.blog .tag img {
  margin-right: 5px;
}
.blog .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: -0.04em;
  color: #191c20;
  margin-bottom: 15px;
}
.blog .para {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
  margin-bottom: 20px;
}
.blog .blackBtn {
  padding: 12px 16px;
  background: #191c20;
  border-radius: 8px;
  color: #fff;
}
.blog .boxarea img {
  width: 100%;
}
.blog .boxarea h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #191c20;
  margin: 20px 0 16px 0;
}
.blog .boxarea p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
}
.blog .boxarea a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #234bfa;
}
.blog .boxarea .tags {
  padding: 4px 16px;
  border-radius: 80px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: -0.04em;
}
.blog .boxarea .tags.career {
  background: rgba(35, 75, 250, 0.08);
  color: #234bfa;
}
.blog .boxarea .tags.knowledge {
  background: rgba(9, 207, 131, 0.08);
  color: #09cf83;
}

.blog .boxarea .time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: #51555a;
  display: inline-block;
  margin: 0 13px;
}

/* Hire Section*/

.hire {
  padding: 0 0 100px 0;
}
.hire .background {
  background: #191c20;
  border-radius: 16px;
  padding: 80px;
}

@media (max-width: 767px) {
	.hire .background {
		padding: 40px;
	}
}

.hire .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin: 0 0 20px 0;
}
.hire .para {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.hire .whiteBtn {
  padding: 5px 16px;
  background: #ffffff;
  border-radius: 8px;
  color: #191c20 !important;
  float: right;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next,
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  background: #ececec;
  border-radius: 120px;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}

.siteFooter {
  background: #fff;
  color: #acadaf;
  font-size: 14px;
}
.siteFooter h3 {
  font-size: 18px;
  margin-bottom: 40px;
  color: #191c20;
}
.siteFooter ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.siteFooter ul li {
  margin-bottom: 10px;
}
.siteFooter a {
  color: inherit;
  text-decoration: none;
}
.siteFooter a:hover {
  color: #234efa;
}
.siteFooter .upperFooter {
  padding-top: 70px;
  padding-bottom: 70px;
  border-bottom: 1px solid rgba(133, 147, 163, 0.16);
}
.siteFooter .upperFooter p {
  font-size: 14px;
  max-width: 550px;
}
.siteFooter .upperFooter .logoBox {
  margin-bottom: 30px;
}
.siteFooter .upperFooter .contactBox a {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
  display: inline-block;
  color: #191c20;
}
.siteFooter .bottomFooter {
  padding-top: 70px;
  padding-bottom: 70px;
}
.siteFooter .bottomFooter .td-row {
  justify-content: space-between;
}
.siteFooter .bottomFooter p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #191c20;
  margin: 0 0 5px 0;
}
.siteFooter .bottomFooter small {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: -0.04em;
  color: rgba(25, 28, 32, 0.64);
}
.siteFooter .bottomFooter ul li {
  margin-bottom: 0;
  margin-left: 30px;
  display: inline-block;
}

/*=============== Responsive ====================*/
@media (max-width: 1100px) {
  .td-container {
    max-width: 90%;
  }
  .secPadding {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .heroSec .profileBox {
    width: 100%;
    min-width: 1px;
  }
  .heroSec h2 {
    font-size: 46px;
  }
  .heroSec .profileBox::before {
    left: 20px;
    top: 20px;
  }
  .heroSec .profileBox::after {
    left: 40px;
    top: 40px;
  }
  .logoSection .td-col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .logoSection .logoImg {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .siteFooter .td-col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .siteFooter .td-col-2 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .siteFooter h3 {
    margin-bottom: 20px;
  }
  .sliderSection .content-area .img-part {
    text-align: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 1023px) {
  #mobileMenu,
  #closeMenu {
    display: block !important;
    cursor: pointer;
  }
  #mainMenu {
    position: fixed;
    right: -250px;
    top: 0;
    width: 250px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
  }
  #mainMenu.open {
    right: 0;
  }
  .siteHeader .td-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .siteHeader .btnBox {
    margin-right: 20px;
  }
  .siteHeader ul li {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #f7f7f7;
  }
  #closeMenu {
    text-align: right;
  }
  #closeMenu span {
    display: inline-block;
    padding: 13px 15px;
    line-height: 1;
    background-color: #234efa;
    color: #fff;
  }
  .heroSec {
    padding-top: 80px;
    padding-bottom: 70px;
  }
  .heroSec .td-col-7,
  .heroSec .td-col-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .heroSec .td-col-7 {
    order: 2;
  }
  .heroSec .td-col-5 {
    order: 1;
  }
  .heroSec .profileBox {
    max-width: 350px;
    margin-bottom: 100px;
  }
  .heroSec .mainContent {
    text-align: center;
  }
  .heroSec .bottomContent .td-row {
    justify-content: center;
  }
  .heroSec .bottomContent {
    text-align: center;
  }
  .siteFooter .upperFooter,
  .siteFooter .bottomFooter {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .heroSec h2 {
    font-size: 36px;
  }
  .heroSec .bottomContent h3 {
    font-size: 24px;
  }
  .heroSec .mainContent {
    margin-bottom: 50px;
  }
  .siteFooter .td-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .logoSection .td-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .siteFooter .linkBox,
  .siteFooter .td-col-6 {
    margin-bottom: 30px;
  }
  .td-col-1,
  .td-col-2,
  .td-col-3,
  .td-col-4,
  .td-col-5,
  .td-col-6,
  .td-col-7,
  .td-col-8,
  .td-col-9,
  .td-col-10,
  .td-col-11,
  .td-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .boxarea {
    margin: 0 0 45px 0;
  }
  .ourProcess .boxarea.bottom-arrow:after,
  .ourProcess .boxarea.top-arrow:after {
    display: none;
  }
}

@media (max-width: 599px) {
  .heroSec .bottomContent .td-col {
    width: 100%;
  }
  .bottomFooter {
    text-align: center;
  }
  .bottomFooter .copyright {
    margin-bottom: 15px;
  }
  .logoSection .td-col,
  .bottomFooter .td-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .siteFooter .bottomFooter ul li {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 380px) {
  .heroSec .profileBox h3 {
    font-size: 24px;
  }
  .heroSec .profileBox p {
    font-size: 18px;
  }
  .heroSec .profileBox::before {
    left: 10px;
    top: 10px;
  }
  .heroSec .profileBox::after {
    left: 20px;
    top: 20px;
  }
  .siteHeader .btnBox a {
    padding: 10px;
  }
  .siteHeader .btnBox {
    margin-right: 10px;
  }
  .siteHeader .logoBox img {
    max-width: 90px;
  }
  .heroSec .profileBox .btn {
    min-width: 150px;
  }
  .heroSec {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* .swiper {
	width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
  }
  
  .swiper-slide {
	background-position: center;
	background-size: cover;
	width: 300px;
	height: 300px;
  }
  
  .swiper-slide img {
	display: block;
	width: 100%;
  } */
