.services-section {
  padding: 80px 120px;
  background-color: #ffffff;
}

.service-header {
  text-align: center;
  margin-bottom: 60px;
}

.service-tag {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background-color: #234bfa0a;
  padding: 8px 16px;
  border-radius: 80px;
  margin-bottom: 16px;
}

.service-tag span {
  color: #234BFA;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.52px;
}

.service-header h1 {
  font-size: 48px;
  font-weight: 700;
  color: #191C20;
  margin-bottom: 16px;
  letter-spacing: -1.92px;
  line-height: 57.6px;
}

.service-header p {
  font-size: 16px;
  color: #191C20CC;
  max-width: 540px;
  margin: 0 auto;
  line-height: 28px;
}

.service-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  border-radius: 32px;
  padding: 40px;
  cursor: none !important; /* Ensure cursor is none */
  border: 1px solid #191C2014;
  transition: all 0.3s ease;
}

/* Active state for service cards */
.service-card.active {
  background-color: #234BFA !important;
}

.service-card-header h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 36px;
  transition: color 0.3s ease;
  color: #191C20;
}

.service-card.active .service-card-header h3 {
  color: #FFFFFF;
}

.service-content {
  margin-top: 16px;
  overflow: hidden;
}

.service-content p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.64px;
  color: #EBEBEB;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-section {
    padding: 40px 20px;
  }

  .service-header h1 {
    font-size: 38px;
    line-height: 46px;
  }

  .service-card {
    padding: 24px;
  }

  .service-card-header h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .service-content p {
    font-size: 14px;
    line-height: 24px;
  }
}