.portfolio-container {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
}

.portfolio-content {
  max-width: 1200px;
  width: 100%;
  min-height: 1895px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  padding-top: 174px;
  margin: 0 auto;
}

.header-section {
  width: 100%;
  /* height: 257px; */
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title-group {
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.badge {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    background: rgba(35, 75, 250, 0.04);
    border-radius: 80px;
    gap: 10px;
    width: fit-content;
}

.badge-text {
    color: #234BFA;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 22.75px;
}

.main-title {
    color: #191C20;
    font-size: 48px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 60px;
    text-transform: capitalize;
}

.divider {
    width: 100%;
    height: 1px;
    background: #191C20;
}

.filter-section {
    width: 100%;
    padding: 8px;
    background: #F6F7F9;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-group {
    display: flex;
    gap: 8px;
}

.filter-item, .reset-filter {
    height: 45px;
    padding: 12px;
    background: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: #191C20;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 16.8px;
}

.projects-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.project-row {
    padding: 0;
    display: flex;
    gap: 24px;
    width: 100%;
}

.project-card {
    flex: 1;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.project-card.full-width {
    width: 100%;
}

.project-image {
    width: 100%;
    height: 360px;
    border-radius: 4px;
    object-fit: cover;
}

.project-info {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.project-info h2 {
    color: #191C20;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 36px;
}

.project-info p {
    color: #51555A;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 28px;
}

.project-tags {
    display: flex;
    gap: 8px;
}

.tag {
    padding: 4px 16px;
    background: rgba(235, 235, 235, 0.64);
    border-radius: 80px;
    color: #191C20;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 21px;
}