.blogImg img {width: 100%;border-radius: 15px;}
.bigBlog .blogImg, .bigBlog .metaData {margin-bottom: 20px;}
.blogContent h3 {font-weight: 400;margin-bottom: 15px;}
.bigBlog h3 {font-size: 24px;}
.smallBlog {display: flex;flex-wrap: wrap;margin-bottom: 30px;}
.smallBlog .metaData {margin-bottom: 20px;}
.smallBlog .blogImg {flex: 0 0 35%;max-width: 35%;margin-bottom: 0;}
.smallBlog .blogContent {flex: 0 0 65%;padding-left: 20px;max-width: 65%;}
.blogSec .mainContent h2 {font-size: 48px;margin-bottom: 20px;}
.blogSec .mainContent {max-width: 700px;margin: 0 auto 50px;width: 100%;}
.blogSec .tag {margin-bottom: 20px;}
.blogSec .tag span {color: #234BFA;background-color: rgba(35,75,250,0.04);line-height: 1;padding: 10px 15px; border-radius: 20px;}
.metaData {font-size: 10px;color: #51555A;}
.metaData span {display: inline-block;}
.metaData .tag {font-size: 12px;margin-right: 10px;color: #234BFA;background-color: rgba(35,75,250,0.04);line-height: 1;padding: 10px 15px; border-radius: 20px;margin-bottom: 0;}
.metaData .purple {color: #7B61FF;background-color: rgba(127,97,255,0.04);}
.metaData .green {color: #09CF83;background-color: rgba(9,207,131,0.04);}
.blogBoxes {margin-bottom: 50px;}
.blogSec .btn {color: #000; text-decoration: none !important;padding: 10px 15px;border:1px solid #000;border-radius: 10px;}
.blogSec .btn:hover {background-color: #234efa;color: #fff;border-color: #234efa;}



/*=============== Responsive ====================*/
@media (max-width: 1400px){
	.td-container {max-width: 90%;}
}


@media (max-width: 1100px){
    .blogSec .td-col-7, .blogSec .td-col-5 {flex: 0 0 100%;max-width: 100%;}
	.bigBlog {margin-bottom: 50px;}
}

@media (max-width: 479px){
	.smallBlog .blogImg, .smallBlog .blogContent {flex: 0 0 100%;padding-left: 0px;max-width: 100%;}
	.smallBlog .blogImg {margin-bottom: 15px;}
	.blogSec .mainContent h2 {font-size: 38px;}
	.blogBoxes {margin-bottom: 0; }
}