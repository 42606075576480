/* Contact Form Styles */
.contactSec .contactForm { max-width: 550px; width: 100%; }
.contactSec .contactForm h1 { font-size: 72px; font-weight: 700; margin-bottom: 30px; }
.contactSec .contactForm > p { margin-bottom: 50px; max-width: 400px; width: 100%; }
.formBox .formGrp { margin-bottom: 30px; }
.formBox .formGrp > label { font-size: 18px; display: block; margin-bottom: 10px; font-weight: 500; }
.formBox .formGrp > input, .formBox .formGrp > textarea { width: 100%; display: block; padding: 15px; font-family: sans-serif; font-size: 16px; border: 1px solid rgba(133,147,163,0.32); border-radius: 8px; }
.formBox .buttonGrp { display: flex; flex-wrap: wrap; }
.formBox .buttonGrp .checkBox { margin:5px 15px 5px 0; }
.formBox .buttonGrp .checkBox input { visibility: hidden; position: absolute; width: 0; height: 0; overflow: hidden; }

/* Updated checkbox label styles */
.formBox .buttonGrp .checkBox label { 
  padding: 15px; 
  display: inline-block; 
  border: none; 
  border-radius: 50px; 
  cursor: none !important; 
  transition: all ease 0.3s; 
  color: #000; 
  background-color: #f5f5f5;
}

/* Hover effect for checkbox labels */
.formBox .buttonGrp .checkBox label:hover { 
  background-color: #e0e0e0;
}

/* Selected state for checkbox labels - blue background with white text */
.formBox .buttonGrp .checkBox input:checked + label { 
  background-color: #234BFA; 
  color: #fff; 
  border: none;
}

/* Make "Hire a Developer" blue by default to show it's selected */
#general0:checked + label {
  background-color: #234BFA;
  color: #fff;
}

/* Updated submit button style to match header button */
.formBox .submitBtn .btn { 
  padding: 12px 20px; 
  font-size: 16px; 
  border: none; 
  border-radius: 10px; 
  background-color: #234BFA; 
  color: #fff; 
  font-weight: 600; 
  cursor: none !important;
  transition: all 0.3s ease;
}

.formBox .submitBtn .btn:hover { 
  background-color: #1a3ac7; /* Slightly darker blue on hover */
}

/* Right Section Styles */
.contactSec .rightSec .imageBox { margin-bottom: 50px; }
.contactSec .rightSec .imageBox img { width: 100%; display: block; border-radius: 15px; max-height: 500px; object-fit: cover; }
.contactDetails .td-row > div { margin-bottom: 50px; }
.contactDetails h3 { margin-bottom: 15px; }
.contactDetails p { margin-bottom: 5px; }
.contactDetails a { 
  cursor: none !important;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}
.contactDetails a:hover {
  color: #234BFA;
}

/* Testimonials Section with Swiper */
.testimonials {
    background-color: #f7f7f7;
    position: relative;
    padding: 60px 0;
}

/* Swiper Container */
.testimonials .swiper {
    width: 100%;
    height: 100%;
    padding: 20px 0;
}

/* Slide Content */
.testimonials .slideContent {
    padding: 0 15%;
    text-align: center;
}

.testimonials .slideContent > p {
    font-size: 21px;
    margin-bottom: 50px;
    font-weight: 500;
}

/* Profile Section */
.testimonials .profile {
    margin-bottom: 15px;
}

.testimonials .profile img {
    border-radius: 50%;
    margin-bottom: 20px;
    display: inline-block;
}

.testimonials .profile h3 {
    margin-bottom: 10px;
}

/* Stars Section */
.testimonials .stars img {
    display: inline-block;
    width: auto;
}

/* Swiper Navigation Buttons */
.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
    width: 35px;
    height: 35px;
    background-color: #ececec;
    border-radius: 50%;
    color: #000;
    cursor: none !important;
}

.testimonials .swiper-button-next:after,
.testimonials .swiper-button-prev:after {
    font-size: 15px;
    font-weight: bold;
}

.testimonials .swiper-button-prev {
    left: 10%;
}

.testimonials .swiper-button-next {
    right: 10%;
}

/* Swiper Pagination */
.testimonials .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    cursor: none !important;
}

.testimonials .swiper-pagination-bullet-active {
    background: #000;
    opacity: 1;
}

/* Responsive Styles */
@media (max-width: 1100px) {
    .contactSec .contactForm h1 { font-size: 50px; }
}

@media (max-width: 1023px) {
    .contactSec .td-col-6 { flex: 0 0 100%; max-width: 100%; }
    .contactSec .rightSec .td-col-6 { flex: 0 0 50%; max-width: 50%; }
    .contactSec .contactForm { max-width: 100%; margin-bottom: 50px; }
}

@media (max-width: 767px) {
    .testimonials .slideContent > p { font-size: 18px; }
    .testimonials .slideContent { padding: 0 30px; }
    .testimonials .swiper-button-prev { left: 20px; }
    .testimonials .swiper-button-next { right: 20px; }
}

@media (max-width: 479px) {
    .contactSec .rightSec .td-col-6 { flex: 0 0 100%; max-width: 100%; }
    .testimonials .swiper-button-next,
    .testimonials .swiper-button-prev {
        display: none;
    }
}